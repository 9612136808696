import {
  StepFive,
  StepFour,
  StepThree,
  StepTwo,
  WizardHeader
} from '../../components/wizard';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerContext } from '../../contexts/player';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { Container } from 'react-bootstrap';
import { DisplayLanguageContext } from '../../contexts/display-languages';

export const JoinGamePage = () => {
  const [state] = useContext(GameDocumentContext);
  const [player, setPlayer] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [isLanguangeAvailabe, setIsLanguageIsAvailable] =
    useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const languageTitle =
    generateTitleById(
      'd594b340-e6db-4822-9dfa-6701afbd5677',
      state,
      displayLanguageContext.displayLanguageSelected.resources!,
      'game'
    ) || 'Language';
  const playerNameTitle =
    generateTitleById(
      '00100845-d632-4d60-9140-46dddb187781',
      state,
      displayLanguageContext.displayLanguageSelected.resources!,
      'game'
    ) || 'Your player name';

  const yourAvatarTitle =
    generateTitleById(
      'ab253e81-5612-4cc8-93b3-56cb2e3a056b',
      state,
      displayLanguageContext.displayLanguageSelected.resources!,
      'game'
    ) || 'Your avatar';

  const navigate = useNavigate();

  const handleLeftArrowClick = () => {
    if (currentStep === 1) navigate('/games');
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const updateStepCallback = (nextStep: number) => {
    setCurrentStep(nextStep);
  };

  const renderWithLanguange = (): JSX.Element => {
    return (
      <>
        {currentStep === 1 && (
          <StepTwo
            nextStepCallback={updateStepCallback}
            currentStep={currentStep}
          />
        )}
        {currentStep === 2 && (
          <StepThree
            nextStepCallback={updateStepCallback}
            currentStep={currentStep}
          />
        )}
        {currentStep === 3 && (
          <StepFour
            nextStepCallback={updateStepCallback}
            currentStep={currentStep}
          />
        )}
        {currentStep === 4 && (
          <StepFive
            nextStepCallback={updateStepCallback}
            currentStep={currentStep}
          />
        )}
      </>
    );
  };

  const renderWithoutLanguage = (): JSX.Element => {
    return (
      <>
        {currentStep === 1 && (
          <StepThree
            nextStepCallback={updateStepCallback}
            currentStep={currentStep}
          />
        )}
        {currentStep === 2 && (
          <StepFour
            nextStepCallback={updateStepCallback}
            currentStep={currentStep}
          />
        )}
        {currentStep === 3 && (
          <StepFive
            nextStepCallback={updateStepCallback}
            currentStep={currentStep}
          />
        )}
      </>
    );
  };

  const generateCurrentHeader = (): string => {
    switch (currentStep) {
      case 1:
        if (isLanguangeAvailabe) {
          return languageTitle;
        } else {
          return playerNameTitle;
        }
      case 2:
        if (isLanguangeAvailabe) {
          return playerNameTitle;
        } else {
          return yourAvatarTitle;
        }
      case 3:
        if (isLanguangeAvailabe) {
          return yourAvatarTitle;
        } else {
          return '';
        }
    }
    return '';
  };

  useEffect(() => {
    if (
      state.gameDocument?.resourcePacks &&
      state.gameDocument.resourcePacks.length > 0
    ) {
      setIsLanguageIsAvailable(true);
    }
    //set default display language url
    if (
      player.isLoaded &&
      !player.playerState?.displayLanguageUrl &&
      state.gameDocument?.overview &&
      state.gameDocument?.overview?.displayLanguagesUrl
    ) {
      setPlayer({
        ...player,
        playerState: {
          ...player.playerState!,
          displayLanguageUrl: state.gameDocument?.overview?.displayLanguagesUrl
        }
      });
    }
    //eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (player.isLoaded) {
      if (isLanguangeAvailabe && !player.playerState?.language) {
        setCurrentStep(1);
      } else if (!player.playerState?.name) {
        setCurrentStep(isLanguangeAvailabe ? 2 : 1);
      } else if (!player.playerState?.avatarImage) {
        setCurrentStep(isLanguangeAvailabe ? 3 : 2);
      }
    }
  }, [player, isLanguangeAvailabe]);

  return (
    <Container className={'rounded shadow'}>
      {state.isLoaded && ( // Should show loader or something while waiting game document generated
        <>
          <WizardHeader
            step={currentStep}
            handleOnClick={handleLeftArrowClick}
            header={generateCurrentHeader()}
            stepCount={isLanguangeAvailabe ? 5 : 4}
          />
          {isLanguangeAvailabe
            ? renderWithLanguange()
            : renderWithoutLanguage()}
        </>
      )}
    </Container>
  );
};
