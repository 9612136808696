import { Button } from '@progress/kendo-react-buttons';
import { Error } from '@progress/kendo-react-labels';
import { CardSubtitle } from '@progress/kendo-react-layout';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Col, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';
import {
  GetFileExtensionsBySource,
  dataURItoBlob,
  loadImageToBlobUrl
} from '../../utils/image-helper';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { uuid } from '../../types/common-helper';

const generateRandomString = () => {
  return uuid().split('-').join('').substring(0, 5);
};

type Props = {
  isReadonly?: boolean;
  selectedImage?: string;
  onSelectedImageCallback?: (blobUrl: string | File, size?: number) => void;
  isDisabled?: boolean;
  isFileUploaded?: boolean;
  formId: string;
};

const AnswerVideo = ({
  isReadonly = false,
  selectedImage = '',
  onSelectedImageCallback = () => {},
  isDisabled = false,
  isFileUploaded = false,
  formId
}: Props) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const videoRef = useRef<Webcam>(null);
  const videoPlayback = useRef<HTMLVideoElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [videoBlob, setVideoBlob] = useState<{
    blob: Blob;
    url: string;
  }>();
  const [fileSize, setFileSize] = useState<number>(0);

  const mediaRecorderRef = useRef<any>(null);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
  const [facingMode, setFacingMode] = useState<string>('environment');
  const [blobImage, setBlobImage] = useState<string>(selectedImage);
  const [isDisplayWebcam, setIsDisplayWebcam] = useState<boolean>(false);
  const [isValidFileExtension, setIsValidFileExtension] =
    useState<boolean>(true);
  const [fileUpload, setFileUpload] = useState<File>();

  const handleSelectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const blobUrl = loadImageToBlobUrl(file);
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
      const baseName = fileName.slice(0, fileName.lastIndexOf('.'));
      const newFileName = `${baseName}-${generateRandomString()}${fileExtension}`;

      setIsValidFileExtension(true);

      let extensionFound = undefined;

      if (fileExtension) {
        // set file ext checker
        let imageExtensionList = GetFileExtensionsBySource(
          'Assets - Task Content - Video Answer Type'
        );

        if (imageExtensionList) {
          extensionFound = imageExtensionList.find(
            (validFileExtension) =>
              validFileExtension ===
              fileExtension.replaceAll('.', '').toLowerCase()
          );
          setIsValidFileExtension(extensionFound ? true : false);
        }
      }

      if (extensionFound) {
        const newFile = new File([file], newFileName, { type: file.type });
        setBlobImage(blobUrl);
        onSelectedImageCallback(newFile, newFile.size ?? 0);
        setFileUpload(newFile);
      } else {
        setBlobImage('');
        onSelectedImageCallback('');
        setFileUpload(undefined);
      }
    }
  };

  const onAddImageClick = () => inputFile.current && inputFile.current.click();

  const turnOnCameraClick = () => {
    setIsDisplayWebcam(true);
    setIsValidFileExtension(true);
  };

  const turnOffCameraClick = () => {
    const { stream } = videoRef.current!;
    const tracks = stream?.getTracks();
    tracks?.forEach((item) => item.stop());
    setBlobImage('');
    onSelectedImageCallback('');
    setIsDisplayWebcam(false);
  };

  const onTakePictureClickHandler = () => {
    const screenShoot = videoRef.current?.getScreenshot();
    if (screenShoot && screenShoot !== '') {
      fetch(screenShoot)
        .then((result) => {
          const imageFile = dataURItoBlob(result.url);
          let myFile = imageFile;
          if (imageFile instanceof Blob) {
            const extension = imageFile.type.split('/')[1];
            myFile = new File([imageFile], `Blob-${formId}.${extension}`, {
              type: imageFile.type
            });
          }
          onSelectedImageCallback(myFile as File, imageFile.size ?? 0);
          setBlobImage(result.url);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsDisplayWebcam(false));
    }
  };

  const getFileExtension = (filename: string) => {
    // get file extension
    const extension = filename.substring(
      filename.lastIndexOf('.') + 1,
      filename.length
    );
    return extension;
  };
  const allowedImages = () => {
    return (
      getFileExtension(selectedImage) === 'png' ||
      getFileExtension(selectedImage) === 'jpg' ||
      getFileExtension(selectedImage) === 'jpeg' ||
      getFileExtension(selectedImage) === 'gif' ||
      getFileExtension(selectedImage) === 'heic' ||
      getFileExtension(selectedImage) === ''
    );
  };

  const allowedVideo = () => {
    return (
      getFileExtension(selectedImage) === 'mp4' ||
      getFileExtension(selectedImage) === 'mov' ||
      getFileExtension(selectedImage) === 'wmv' ||
      getFileExtension(selectedImage) === 'avi'
    );
  };

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(videoRef?.current?.stream!, {
      mimeType: MediaRecorder.isTypeSupported('video/mp4')
        ? 'video/mp4'
        : 'video/webm'
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [videoRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }: any) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    loadVideo();
  }, [videoRef, mediaRecorderRef, setCapturing]);

  const loadVideo = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: MediaRecorder.isTypeSupported('video/mp4')
          ? 'video/mp4'
          : 'video/webm'
      });
      const url = URL.createObjectURL(blob);
      if (url) {
        setVideoBlob({
          blob,
          url
        });
        setFileSize(blob.size);
      }
    }
  }, [recordedChunks]);

  const handleSwitchCamera = useCallback(() => {
    setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'));
  }, [setFacingMode]);

  const submitVideo = () => {
    if (videoBlob) {
      let myFile = videoBlob.blob;
      if (myFile instanceof Blob) {
        const extension = myFile.type.split('/')[1];
        myFile = new File(
          [myFile],
          `recorded-video-${generateRandomString()}.${extension}`,
          {
            type: myFile.type
          }
        );
      }
      onSelectedImageCallback(myFile as File, fileSize);
    }
  };

  useEffect(() => {
    if (recordedChunks.length > 0) {
      loadVideo();
      setIsValidFileExtension(true);
    }
  }, [recordedChunks]);

  useEffect(() => {
    setFileSize(0);
  }, []);

  return (
    <div id={'answer-file'}>
      <Row className={'py-2'}>
        <Col>
          <div className={'border text-center h-100 position-relative'}>
            {recordedChunks.length > 0 && (
              <div
                className={
                  'video-playback-wrapper d-flex flex-column justify-content-space-between align-items-center mt-4'
                }>
                <video
                  controls
                  preload={'auto'}
                  width={'650'}
                  height={'400'}
                  src={videoBlob?.url}
                />

                <div className={'video-playback-action mt-4 '}>
                  {!isFileUploaded && (
                    <>
                      <Button
                        className={'bg-danger text-white mr-3'}
                        onClick={() => {
                          setIsDisplayWebcam(true);
                          setRecordedChunks([]);
                        }}
                        disabled={isDisabled}>
                        <span className={'pb-2'}>
                          {generateTitleById(
                            '5163981d-0d89-4afb-b0d8-f2d0e42de142',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Cancel'}
                        </span>
                      </Button>
                      <Button
                        className={'bg-success text-white'}
                        onClick={() => submitVideo()}
                        disabled={isDisabled}>
                        {generateTitleById(
                          'e1527e98-9fba-4a13-b082-7c510d10a4e0',
                          gameDocument,
                          displayLanguageContext.displayLanguageSelected
                            .resources!,
                          'game'
                        ) || 'Submit Video'}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
            {isDisplayWebcam && recordedChunks.length <= 0 && (
              <div className={'video-record-action'}>
                {capturing ? (
                  <Button
                    onClick={handleStopCaptureClick}
                    className={'video-icon'}>
                    <span className={'text-danger material-symbols-outlined'}>
                      stop_circle
                    </span>
                  </Button>
                ) : (
                  <Button
                    onClick={handleStartCaptureClick}
                    className={'video-icon'}>
                    <span className={'text-danger material-symbols-outlined'}>
                      screen_record
                    </span>
                  </Button>
                )}
                {capturing && (
                  <p className={'text-danger mb-0'}>
                    <strong>
                      {generateTitleById(
                        '3d6b40c6-d321-441b-8b8b-5982da856e9a',
                        gameDocument,
                        displayLanguageContext.displayLanguageSelected
                          .resources!,
                        'game'
                      ) || 'Recording...'}
                    </strong>
                  </p>
                )}
                <Button
                  className={'video-icon right-position'}
                  onClick={handleSwitchCamera}>
                  <span className={'material-symbols-outlined text-white'}>
                    cameraswitch
                  </span>
                </Button>
              </div>
            )}

            {isDisplayWebcam && recordedChunks.length <= 0 ? (
              <Webcam
                id={'answer-webcam'}
                className={'py-2'}
                audio={false}
                videoConstraints={{
                  height: 400,
                  width: 650,
                  facingMode: facingMode ?? 'user'
                }}
                screenshotFormat={'image/jpeg'}
                autoPlay={true}
                ref={videoRef}></Webcam>
            ) : (
              <>
                {recordedChunks.length <= 0 ? (
                  <div
                    className={
                      'd-flex justify-content-center align-items-center py-5'
                    }>
                    {blobImage === '' || selectedImage === '' ? (
                      <span
                        className={
                          'material-symbols-outlined text-success text-size-64'
                        }>
                        wallpaper
                      </span>
                    ) : allowedImages() ? (
                      <video
                        controls
                        preload={'auto'}
                        width={'650'}
                        height={'400'}
                        className={'img-fluid img-thumbnail '}
                        playsInline>
                        <source src={blobImage} type={`video/mp4`} />
                        <source src={blobImage} type={`video/webp`} />
                        Your browser does not support the video tag.
                      </video>
                    ) : allowedVideo() ? (
                      <video
                        controls
                        preload={'auto'}
                        width={'650'}
                        height={'400'}
                        className={'img-fluid img-thumbnail '}
                        playsInline>
                        <source src={blobImage} type={`video/mp4`} />
                        <source src={blobImage} type={`video/webp`} />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p>
                        <span
                          className={
                            'material-symbols-outlined text-primary text-size-64'
                          }>
                          cloud_done
                        </span>
                        <br />
                        {!isReadonly ? (
                          (fileUpload?.name ??
                          decodeURI(
                            selectedImage.substring(
                              selectedImage.lastIndexOf('/') + 1
                            )
                          ))
                        ) : (
                          <>
                            <span>
                              {decodeURI(
                                selectedImage.substring(
                                  selectedImage.lastIndexOf('/') + 1
                                )
                              )}
                            </span>{' '}
                            <br />
                            <a href={selectedImage}>Download</a>
                          </>
                        )}
                      </p>
                    )}
                  </div>
                ) : (
                  // to hide "preview" template when video already recorded
                  <div
                    className={
                      'd-flex justify-content-center align-items-center py-3'
                    }></div>
                )}
                {blobImage === '' && recordedChunks.length <= 0 && (
                  <CardSubtitle>
                    {generateTitleById(
                      '567963b7-47c0-40e9-8659-2e43a5a5befb',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Preview'}
                  </CardSubtitle>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      {!isReadonly && recordedChunks.length <= 0 && (
        <Row
          className={'d-flex justify-content-center align-items-center py-2'}>
          {!isValidFileExtension && (
            <Col className={'mb-4'} md={12}>
              <Error>
                {generateTitleById(
                  '9ff35578-4946-4900-a4a3-e76f776ab4ca',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) || 'Invalid File Type: Please upload video files only.'}
              </Error>
            </Col>
          )}
          {!isDisabled && (
            <>
              {!isDisplayWebcam && (
                <Col md={6}>
                  <Button
                    className={'w-100 upload-button p-2 mb-2'}
                    onClick={
                      isDisplayWebcam
                        ? onTakePictureClickHandler
                        : onAddImageClick
                    }>
                    <div
                      className={
                        'd-flex justify-content-center align-items-center gap-3'
                      }>
                      <span
                        className={
                          'material-symbols-outlined mr-2 text-success'
                        }
                        role={'button'}>
                        add_photo_alternate
                      </span>
                      <span>
                        {isDisplayWebcam
                          ? generateTitleById(
                              '7b63892e-236c-4b6a-bfec-d5d3d66794af',
                              gameDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'game'
                            ) || 'Take picture'
                          : generateTitleById(
                              'b6d7d97b-37bd-4ef9-aa9e-0e02a404a375',
                              gameDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'game'
                            ) || 'Add from files'}
                      </span>
                    </div>
                  </Button>
                  <input
                    type={'file'}
                    id={'answer-file-upload'}
                    className={'d-none'}
                    onChange={handleSelectImage}
                    onClick={(event) => {
                      event.currentTarget.value = '';
                    }}
                    ref={inputFile}
                    accept={'video/*'}
                  />
                </Col>
              )}
              <Col md={isDisplayWebcam ? 10 : 6}>
                <Button
                  className={'w-100 upload-button p-2 mb-2'}
                  onClick={
                    isDisplayWebcam ? turnOffCameraClick : turnOnCameraClick
                  }>
                  <div
                    className={
                      'd-flex justify-content-center align-items-center gap-3'
                    }>
                    <span
                      className={'material-symbols-outlined mr-2 text-success'}
                      role={'button'}>
                      add_a_photo
                    </span>
                    <span>
                      {isDisplayWebcam
                        ? generateTitleById(
                            '59473aee-f6c3-4657-aa6e-f72a9726e4dc',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Turn off camera'
                        : generateTitleById(
                            '51689c93-1e57-4c72-9308-5deab301614a',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Take from camera'}
                    </span>
                  </div>
                </Button>
              </Col>
            </>
          )}
        </Row>
      )}
    </div>
  );
};

export default AnswerVideo;
