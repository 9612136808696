import React, { useContext, useEffect, useState } from 'react';
import { WizardContent } from '.';
import { GameDocumentContext } from '../../contexts/game-document';
import { ResourcePackEntity } from '../../types/game-document/entities/';
import { PreGameWizard } from '../../types/pre-game';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import { PlayerContext } from '../../contexts/player';
import {
  GetDisplayLanguageJsonAsync,
  generateTitleById
} from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { Language } from '../../types/game-document/entities/language';

const defaultLanguage: Language = {
  id: 'default',
  name: 'Default',
  displayLanguageUrl: '',
  displayLanguage: ''
};

const StepTwo = ({ nextStepCallback, currentStep = 1 }: PreGameWizard) => {
  const [state] = useContext(GameDocumentContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [languages, setLanguages] = useState<Language[]>([defaultLanguage]);

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    playerState.playerState?.language || defaultLanguage
  );

  const handleClickNext = () => {
    nextStepCallback(currentStep + 1);
    if (playerState.isLoaded) {
      setPlayerState({
        ...playerState,
        playerState: {
          ...playerState.playerState!,
          language:
            selectedLanguage.id === 'default' ? 'Default' : selectedLanguage,
          displayLanguageUrl: selectedLanguage.displayLanguageUrl
        }
      });
    }
  };

  const [displayLanguageContext, setDisplayLanguagesContext] = useContext(
    DisplayLanguageContext
  );

  const handleOnChange = (event: DropDownListChangeEvent) => {
    setSelectedLanguage({ ...event.target.value });

    let url =
      event.target.value.displayLanguageUrl ||
      state.gameDocument?.overview?.displayLanguagesUrl;
    //set selected display language
    GetDisplayLanguageJsonAsync(url).then((response) => {
      setDisplayLanguagesContext((prev) => ({
        ...prev,
        displayLanguageSelected: response
      }));
    });
  };

  useEffect(() => {
    if (
      state.gameDocument?.resourcePacks &&
      state.gameDocument.resourcePacks.length > 0
    ) {
      defaultLanguage.name =
        state.gameDocument.overview?.languages ?? 'Default';
      const defaultLanguageState: Language = {
        ...defaultLanguage,
        displayLanguage: state.gameDocument.overview?.displayLanguages,
        displayLanguageUrl: state.gameDocument.overview?.displayLanguagesUrl
      };
      const availableLanguage: Language[] = [defaultLanguageState].concat(
        state.gameDocument?.resourcePacks?.map<Language>(
          (item: ResourcePackEntity) => ({
            id: item.id,
            name: item.name,
            displayLanguageUrl: item.displayLanguageUrl,
            displayLanguage: item.displayLanguage
          })
        )
      );
      setLanguages([...availableLanguage]);
      setSelectedLanguage(
        (prev) => availableLanguage.find((lang) => lang.id === prev.id) ?? prev
      );
    }
  }, [state]);

  return (
    <>
      <WizardContent
        wizardTitle={
          generateTitleById(
            'bb8f780c-fbd9-48d5-a725-3efb8dcba95c',
            state,
            displayLanguageContext.displayLanguageSelected.resources!,
            'game'
          ) || 'Select language'
        }
        nextButtonText={
          generateTitleById(
            '079c039a-8207-4404-8eb6-558cf5d8477b',
            state,
            displayLanguageContext.displayLanguageSelected.resources!,
            'game'
          ) || 'Next'
        }
        handleNextButtonClicked={handleClickNext}>
        <DropDownList
          data={languages}
          textField={'name'}
          dataItemKey={'id'}
          value={selectedLanguage}
          onChange={handleOnChange}
        />
      </WizardContent>
    </>
  );
};

export default StepTwo;
