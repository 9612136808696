import { QRAssessmentPage } from '../../components/assessment/QR';
import React, { useContext, useEffect, useState } from 'react';
import { PlayerContext } from '../../contexts/player';
import { Col, Container, Row } from 'react-bootstrap';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { getResourceDescription } from '../../utils/assessment-state/resource';
import { getAssessmentDocumentAsync } from '../../services/assessments';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import { useParams } from 'react-router-dom';

export const PlayerAssessmentPage = () => {
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [data, setData] = useState<AssessmentEntity>();
  let { gameCode } = useParams();

  const getAssessmentDocument = async () => {
    let assessmentDocument = (await getAssessmentDocumentAsync(
      gameCode!
    )) as AssessmentEntity;
    if (assessmentDocument) {
      setData(assessmentDocument);
    }
  };

  useEffect(() => {
    getAssessmentDocument();
  }, []);

  return (
    <>
      <div className={'assessment-container'}>
        <Row className={'player-header'}>
          <Col>
            <Container>
              <div className={'d-flex justify-content-between'}>
                <div className={'d-flex align-items-center mt-3 mb-3'}>
                  <div className={'d-flex flex-column justify-content-center'}>
                    <small className={'text-light'}>
                      {getResourceDescription(
                        assessmentDocument?.assessmentDocument?.titleResId!,
                        data!,
                        playerState?.assessmentState?.languageId ?? ''
                      )}
                    </small>
                  </div>
                </div>
                <div className={'d-flex align-items-center mt-3 mb-3'}>
                  <h3 className={'text-light fw-bold mt-3 mb-3'}>
                    {playerState?.assessmentState?.name}
                  </h3>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
        <div className={'d-flex flex-column container'}>
          <div className={'d-flex justify-content-center mt-4'}>
            <QRAssessmentPage />
          </div>
        </div>
      </div>
    </>
  );
};
