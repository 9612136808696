import { GameDocument } from '../../types/game-document';
import { GetResourceEntity } from './resources';

/**
 * Get resource pack's resource by name.
 * @param gameDocument - The Game Document to modify
 * @param name - Name of resource pack
 * @param resourceId - Resource Id you want to search
 * @return The Resource pack's resource
 */
export const GetResourcePackResourceValue = (
  gameDocument: GameDocument,
  name: string,
  resourceId: string
): string => {
  const selectedResourcePack = gameDocument?.resourcePacks?.find(
    (x) => x.name === name
  );

  const value =
    selectedResourcePack?.resources?.find((x) => x.id === resourceId)?.value ??
    '';

  if (!value) {
    return GetResourceEntity(gameDocument, resourceId)?.value ?? '';
  }

  return value;
};
